


export const seoOnboarding = {
    title:"Rosmino | Agua IVESS | Agua a domicilio",
    description: "Disfrutá de la mejor pureza IVESS. Encontrá bidones y botellones de 12 a 20 litros en tu hogar u oficina. ¡Sé parte de nuestra familia IVESS!"
}


export const seoLanding = {
    title:"Agua a domicilio | IVESS teléfono | Rosmino",
    description: "Disfrutá de la pureza IVESS. Agua embotellada de 12 a 20 litros a domicilio. Directo a tu hogar u oficina. Servicio frío calor. ¡Contactános!"
}

export const seoLandingTY = {
    title:"Rosmino | Agua IVESS | Gracias!",
    description: "Gracias! Tu consulta se ha enviado con éxito."
}

export const seoAutomaticRegistration = {
    title:"Rosmino | Agua IVESS | Alta automática",
    description: "Disfrutá de la pureza IVESS. Agua embotellada de 12 a 20 litros a domicilio. Directo a tu hogar u oficina. Servicio frío calor. ¡Contactános!"
}

export const seoManualRegistration = {
    title:"Rosmino | Agua IVESS | Alta con asesor",
    description: "Disfrutá de la pureza IVESS. Agua embotellada de 12 a 20 litros a domicilio. Directo a tu hogar u oficina. Servicio frío calor. ¡Contactános!"
}

export const seoManualRegistrationTY = {
    title:"Rosmino | Agua IVESS | Gracias!",
    description: "Gracias! Tu consulta se ha enviado con éxito."
}

export const seoAutomaticRegistrationTY = {
    title:"Rosmino | Agua IVESS | Gracias!",
    description: "Gracias! Tu consulta se ha enviado con éxito."
}