import React from 'react';
import { Helmet } from 'react-helmet';


const Seo = (props) => {

    const {title, description} = props;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="icon" type="image/png" href="/favicon.png" />
        </Helmet>
    )
}


export default Seo;