export const formValidation = (form, data) => {
    let isValid = false; 
    switch (form) {
        case 'address':
            isValid = data.address !== "" && data.city !== "" && data.lat !== "" &&  data.lng !== "";
            break;
        case 'product_type':
            isValid = data.product_type !== "";
            break;
        case 'new_customer_products':
            isValid = data.filter((prod) => prod.quantity !== 0).length !== 0;
            break;
        case 'visit':
            if(data.product_type === 2) {
                if(data.method_contact !== "" && data.recive_drum === false){ 
                    isValid = true;
                }
                if (data.method_contact !== "" && data.recive_drum === true && data.date_visit === "") {
                    isValid = false;
                }
                if (data.method_contact !== "" && data.recive_drum === true && data.date_visit !== "") {
                    isValid = true;
                }
            } else {
                if(data.product_type === 1 && data.date_visit !== "") {
                    isValid = true;
                }
            }
            break;
        case 'personal_info': 
            isValid = data.name !== "" && data.email !== "" && data.identification !== "" && data.date_of_birth !== "" && data?.phones[0]?.code_area !== "" && 
            data.phones[0].number !== "" && data.phones[0].type !== "";
            break;
        case 'contact':
            isValid = data.method_contact !== "";
            break;
        default:
            isValid = true;
            break;
    }

    return isValid;
}