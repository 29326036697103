import React from 'react';
import {Link} from 'gatsby'
import { OrderProvider } from '../context/OrderContext';


const AppLayout = ({children}) => {
  return (
    <OrderProvider>
      <div className="self-management-app">
        <img src="/forms-izq.svg" className="forms-izq" alt="Icon" />
        <img src="/logo.svg" className="logo" alt="Rosmino IVESS - Logo" />
        <Link to="/">
          <img src="/close.svg" className="close-mobile" alt="close" />
        </Link>
        <div className="app-content">
          {children}
          <Link to="/" className="fs-14 text-center text-grey close-desktop mt-5"><u>Cancelar y volver al sitio</u></Link>
        </div>
        <img src="/forms-der.svg" className="forms-der" alt="Icon" />
      </div>
    </OrderProvider>
  )
}


export default AppLayout;