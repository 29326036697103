import React, { createContext } from 'react';
import axios from 'axios'
import moment from "moment"
import { navigate } from 'gatsby';
import { formValidation } from  '../utils/form-validation';


const OrderContext = createContext();
const { Provider } = OrderContext;


const OrderProvider = ({ children }) => {

  const isBrowser = typeof window !== "undefined";


  /* ------------------------------- UTM SOURCE ------------------------------- */
  const [utm, setUtm] = React.useState("")

  /* ------------------------------- Step Count ------------------------------- */
  const [isValid, setIsValid] = React.useState(false)
  const [step, setStep] = React.useState(0)

  /* ----------------------------- Estado del form ---------------------------- */
  const [recive_drum, setReciveDrum] = React.useState(false)
  const [tag, setTag] = React.useState('')
  const [sending, setSending] = React.useState(false)

  /* -------------------------- Initial Values forms -------------------------- */
  const initialValues = {
    type: 'CALL_CENTER',
    group_id: "",
    route: "",
    product_type: "",
    tag: "call_center",
    address: "",
    lat: "",
    lng: "",
    department: "",
    city: "",
    aditional_info: "",
    name: "",
    email: "",
    identification: "",
    date_of_birth: "",
    phone: "",
    method_contact: "",
    recive_drum: "",
    date_visit: "",
    dispenser: "",
    products: "",
    recive_drum: false,
    early_visit: false
  }


  const [formValues, setFormValues] = React.useReducer(
    (currentValues, newValues) => ({...currentValues, ...newValues}), initialValues
  )
  /* ------------------------- Estado de los products ------------------------- */
  const [offerToSend, setOfferToSend] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [productsOffers, setProductsOffers] = React.useState([])
  const [productsOthers, setProductsOthers] = React.useState([])
  const productsToSend = products.filter((prod) => prod.quantity !== 0)
  const [categories, setCategories] = React.useState([])
  const TotalPrice = products?.map(item => +item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
  let order_type = ""
  let route_available = ""

  /* ---------------------------- Estado de la ruta --------------------------- */
  const [route, setRoute] = React.useState();

  /* --------------------------- Estados del usuario -------------------------- */
  const [phones, setPhones] = React.useState([{
    country_code: 549,
    code_area: "",
    number: "",
    type: 2,
    note: "",
  }])

  /* ----------------------------- Campos del form ---------------------------- */
  const {address, department, city, aditional_info, dispenser, method_contact} = formValues 

  /* ---------------- Funcion modificadora del estado del form ---------------- */
  const handleFormChange = (event) => {
    const {name, value} = event.target
    setFormValues({[name]: value})
  }

  const setIsEarlyVisit = (status) => {
    setFormValues({'early_visit': status})
  }

  /* --------------------------- Validador de steps --------------------------- */
  React.useEffect(() => {
    setIsValid(false)
      if(isBrowser) {
        order_type = window.sessionStorage.getItem('order_type')
        route_available = window.sessionStorage.getItem('route_available')
        if (route_available === "true") {

          if( order_type === "AUTOMATIC_ORDER" ) {
            switch (step) {
              case 0:
                setIsValid(formValidation('address',formValues));
                break;
              case 1:
                setIsValid(formValidation('product_type',formValues));
                break;
              case 2:
                setIsValid(formValidation('new_customer_products',products));
                break;
              case 3:
                setIsValid(formValidation('visit',formValues));
                break;
              case 4:
                setIsValid(formValidation('personal_info',{...formValues, 'phones': phones}));
                break;
            }
          }
          else {
            switch (step) {
              case 0:
                setIsValid(formValidation('address',formValues));
                break;
              case 1:
                setIsValid(formValidation('contact',formValues));
                break;
              case 2:
                setIsValid(formValidation('personal_info',{...formValues, 'phones': phones}));  
                break;
              case 3:
                setIsValid(true);  
                break;
            }
          }
        }
        else {
          switch (step) {
            case 0:
              setIsValid(formValidation('address',formValues));
              break;
            case 1:
              setIsValid(formValidation('personal_info',{...formValues, 'phones': phones}));  
              break;
          }
        }
      }
  },[step, formValues, products, phones, isBrowser, order_type, route_available])

  React.useEffect(() => {
    setProducts(productsOffers.concat(productsOthers))
  },[productsOffers, productsOthers])

  /* ----------------------- Handler para enviar el form ---------------------- */
  const handleSubmit = () =>{
    setSending(true)

    const body = {
      type:  "CALL_CENTER",
      product_type: formValues.product_type,
      tag: "call_center",
      address: {
        address: formValues.address,
        flat_number: formValues.department,
        location: formValues.city,
        note: formValues.aditional_info,
        lat: formValues.lat,
        lng: formValues.lng,
        route:  route.code_router !== "" ? route.code_router : "",
      },
      client: {
        name: formValues.name,
        email: formValues.email,
        identification: formValues.identification,
        date_of_birth: formValues.date_of_birth,
        phones: phones
      },
      visit: {
        method_contact: formValues.method_contact,
        recive_drum: recive_drum,
        date_visit: formValues.date_visit ? moment(formValues.date_visit).format("YYYYMMDD") : "",
        time_visit: route.visit_between_initial !== null && !formValues.early_visit ? `${route.visit_between_initial} a ${route.visit_between_final}` : "",
      },
      total: TotalPrice,
      products: products.filter((prod) => prod.quantity !== 0)
    }
    axios.post(`https://api-landing.rosmino.com.ar/order`, body).then((res) => {
        navigate('/gracias')
    }).catch((err) => {
      console.error(err)
      setSending(false)
    })
  }

  /* ----------------------------- Llamado a apis ----------------------------- */
  React.useEffect(() => {

    /* -------------------------- TRAIGO LAS CATEGORIAS ------------------------- */
    axios.get(`https://api-landing.rosmino.com.ar/product/categories`).then((res) => {
      setCategories(res.data.data)
    }).catch((err) => {console.error(err)})

    /* ----------------------------- GET UTM SOURCE ----------------------------- */
    setTag( isBrowser && window.sessionStorage.getItem('utm'))
  },[]);


  React.useEffect(() => {

    /* ------------------- CHEQUEO SI ESTA LA RUTA DISPONIBLE ------------------ */
    if(formValues.lat !== "" && formValues.lng !== "") {
      axios.get(`https://api-landing.rosmino.com.ar/route?lat=${formValues.lat}&lng=${formValues.lng}`).then((res) => {
        setRoute(res.data)
        if(res.data.route_number === null) {
          isBrowser && sessionStorage.setItem('route_available', false)
        }
        else {
          isBrowser && sessionStorage.setItem('route_available', true)
        }
      }).catch((err) => {console.error(err)})
    }
  },[formValues.lat, formValues.lng]);


  React.useEffect(() => {
    if(formValues.product_type !== "" && formValues.product_type !== 3) {
      axios.get(`https://api-landing.rosmino.com.ar/product?category_id=1`).then((res) => {
        setProductsOffers(res.data.data.map((dato) => ({...dato, quantity: 0})))
      }).catch((err) => {console.error(err)})
    }
    axios.get(`https://api-landing.rosmino.com.ar/product?category_id=2`).then((res) => {
      setProductsOthers(res.data.data.map((dato) => ({...dato, quantity: 0})))
    }).catch((err) => {console.error(err)})
  },[formValues.product_type])

  return (
    <Provider
        value={{
            formValues,
            initialValues, setFormValues,
            handleSubmit,
            handleFormChange,
            setIsEarlyVisit,
            step, setStep, isValid, setIsValid,
            categories, setCategories,
            offerToSend, setOfferToSend,
            products,setProducts,
            productsOthers, productsOffers,
            setProductsOthers, setProductsOffers,
            productsToSend,
            TotalPrice,
            route, setRoute,
            recive_drum, setReciveDrum,
            phones, setPhones,
            utm, setUtm,
            sending,
            setSending,
        }}
    >
        {children}
    </Provider>
  );

};

export { OrderContext, OrderProvider };